<template>
  <q-layout view="hHr Lpr lFr" class="external-facing">
    <q-header elevated class="titlebar bg-background-dark text-white">
      <q-toolbar class="q-px-none q-gutter-x-md full-width">
        <q-toolbar-title shrink class="flex items-center q-px-none">
          <img
            :src="$store.state.branding.logoLight"
            style="width: 145px; align-self: center;"
            class="q-ml-sm q-mr-sm text-white justify-center"
          />

          <q-separator
            v-if="title.length > 0"
            vertical
            class="q-mx-lg"
            color="gray-medium-dark"
          />

          <h6 v-if="title.length > 0">
            {{ title }}
          </h6>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-ajax-bar
      ref="ExternalFacingLoadingBar"
      position="top"
      color="primary"
      size="3px"
      skip-hijack
    />

    <q-page-container>
      <slot name="above-view" />
      <div class="page-content">
        <router-view v-if="!loadingView" v-slot="{ Component }">
          <transition appear name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <slot name="below-view" />
    </q-page-container>

    <footer class="footer bg-background-dark text-white">
      <div class="footer__content">
        <div class="footer__links">
          <a href="https://www.carscience.com/privacy-policy" target="_blank"
            >Privacy Policy</a
          >
          <a href="https://search.carscience.com/ccpa.php" target="_blank"
            >Do Not Sell My Information</a
          >
          <a href="https://www.carscience.com/terms-of-use" target="_blank"
            >Terms of Use</a
          >
          <a href="javascript:void(0)" @click="openNewContactEmail"
            >Contact Us</a
          >
        </div>
        <div class="q-mt-lg text-grey">
          &copy; {{ new Date().getFullYear() }}
          {{ $store.state.branding.name }}. All Rights Reserved.
        </div>
      </div>
    </footer>
  </q-layout>
</template>

<script>
import Chatra from "@chatra/chatra";

export default {
  name: "ExternalFacingSkeleton",
  components: {},
  data() {
    return {
      loadingView: false
    };
  },
  computed: {
    title() {
      return this.$route.meta.title ? this.$route.meta.title : "";
    }
  },
  watch: {
    loadingView(val, prev) {
      if (!val) {
        this.$refs["ExternalFacingLoadingBar"].stop();
      } else if (val && val !== prev) {
        this.$refs["ExternalFacingLoadingBar"].start();
      }
    }
  },
  mounted() {
    Chatra("init", {
      ID: "RdCBEPvAK3MZegqp5"
    });
    window.ChatraSetup = {
      colors: {
        buttonText: "#ffffff",
        buttonBg: "#1d1d1d"
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.external-facing {
  .titlebar {
    max-height: $the-titlebar-height;
    min-height: $the-titlebar-height;
  }
  .page-content {
    //height: calc(100dvh - $the-titlebar-height);
    //padding: $space-xl $space-lg;
  }
  .footer {
    .footer__content {
      padding: $space-xl $space-lg;
      @include display-flex(column, flex-start, center);
      font-size: 0.8em;

      .footer__links {
        @include display-flex(row);
        font-weight: 500;

        a {
          margin: 0 $space-md;
          color: white;
          text-decoration: none;
          font-weight: 400;

          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
